import { UserHelper } from "../ClaimsGate/UserHelper";
import { Claim, Variable } from "@claimsgate/core-types";
import { ClaimDataService, UserDataService } from "@/helpers/ClaimsGate/DataService";
import { getFunnelVariables } from "@claimsgate/core";
import { getFirebaseBackend } from "@/authUtils";

/** Templates a given piece of text using the claim data and user data available in the provided component's state */
export function templateText<
  T extends {
    claimId: string;
    userId: string;
    claimDataService: ClaimDataService;
    userService: UserHelper;
    userDataService: UserDataService;
    isBuilder: boolean;
    funnelVariables: Array<Variable>;
    funnelTitle: string;
    workspaceName: string;
  } & Vue
>(text: string, state: T): string {
  // If we are on the builder just return the original text
  if (state.isBuilder) {
    return text;
  }

  if (!state.userDataService || !state.claimDataService || !state.funnelVariables) {
    return "";
  }

  let templatedText = text;

  const userData = state.userDataService.getCache();
  const claimData = state.claimDataService.getCache();

  // For each string in the text which starts with {{ and ends with }} and include the brackets in the match
  const matches = templatedText.match(/{{(.*?)}}/g);

  if (matches) {
    for (const word of matches) {
      const variableName = word.substring(2, word.length - 2);
      // Replace the word in the original string with test
      if (userData[variableName]) {
        // Replace the word in the original string using the user data in b tags
        templatedText = templatedText.replace(word, `<b>${userData[variableName]}</b>`);
      } else {
        const variable = state.funnelVariables.find((variable) => variable.field === variableName);

        if (variable) {
          const variableValue = claimData[variable.id];
          console.log("variableValue", variableValue, word);

          if (variableValue !== undefined) {
            // Check if the variable type is boolean
            if (variable.type === "boolean") {
              // Convert boolean to 'Yes' or 'No'
              templatedText = templatedText.replace(word, variableValue ? "<b>Yes</b>" : "<b>No</b>");
            } else {
              // Replace the word in the original string with test using v-html directive in vue 2
              templatedText = templatedText.replace(word, `<b>${variableValue}</b>`);
            }
          } else {
            // Replace the word with an _

            if (variable.type === "boolean") {
              templatedText = templatedText.replace(word, "<b>No</b>");
            } else {
              templatedText = templatedText.replace(word, "_");
            }
          }
        } else {
          if (variableName === "funnelTitle") {
            const funnelTitle = state.funnelTitle;
            templatedText = templatedText.replace(word, funnelTitle);
          } else if (variableName === "workspaceName") {
            const workspaceName = state.workspaceName;
            templatedText = templatedText.replace(word, workspaceName);
          }
          // Replace the word with an _
          templatedText = templatedText.replace(word, "_");
        }
      }
    }
  }
  return templatedText;
}

/** Statelessly templates a given piece of text using the claim data **/
export async function statelessTemplateClaimText(text: string, claimData: Claim): Promise<string> {
  let templatedText = text;

  const [funnelVariables, _getFunnelVariablesError] = await getFunnelVariables(
    getFirebaseBackend().firestore(),
    claimData.currentFunnelId
  );

  // For each string in the text which starts with {{ and ends with }} and include the brackets in the match
  const matches = templatedText.match(/{{(.*?)}}/g);

  if (matches) {
    for (const word of matches) {
      const variableName = word.substring(2, word.length - 2);

      const variable = funnelVariables.find((variable) => variable.field === variableName);
      console.log("variable", variable);

      if (variable) {
        const variableValue = claimData[variable.id];
        if (variableValue) {
          // Replace the word in the original string with test using v-html directive in vue 2
          templatedText = templatedText.replace(word, `<b>${variableValue}</b>`);
        } else {
          // Replace the word with an _
          templatedText = templatedText.replace(word, "_");
        }
      } else {
        // Replace the word with an _
        templatedText = templatedText.replace(word, "_");
      }
    }
  }
  return templatedText;
}

function resolveVariable(variableNames: string | string[], funnelVariables: Array<Variable>, claimData: Claim): string {
  if (typeof variableNames === "string") {
    variableNames = [variableNames];
  }

  for (const variableName of variableNames) {
    const variable = funnelVariables.find((v) => v.field === variableName);
    if (variable) {
      const variableValue = claimData[variable.id];
      if (variableValue !== undefined && variableValue !== null) {
        return `<b>${variableValue}</b>`;
      }
    }
  }

  return "_";
}

/** Statelessly templates a given piece of text using the claim data with OR chaining and list support **/
export async function statelessTemplateClaimTextEx(text: string, claimData: Claim): Promise<string> {
  let templatedText = text;

  const [funnelVariables, _getFunnelVariablesError] = await getFunnelVariables(
    getFirebaseBackend().firestore(),
    claimData.currentFunnelId
  );

  // Match both single variables, OR-chained variables, and lists
  const matches = templatedText.match(/(\[.*?\])|{{.*?}}(\|\|{{.*?}})*/g);

  if (matches) {
    for (const match of matches) {
      if (match.startsWith("[") && match.endsWith("]")) {
        // Handle list
        const listItems = match.slice(1, -1).split(",");
        const resolvedItems = listItems
          .map((item) => resolveVariable(item.trim().split("||"), funnelVariables, claimData))
          .filter((item) => item !== null && item !== undefined && item !== "_");
        const replacement = resolvedItems.join(", ");
        templatedText = templatedText.replace(match, replacement || "_");
      } else {
        // Handle single variable or OR-chained variables
        const variableNames = match.split("||").map((v) => v.trim().replace(/^{{|}}$/g, ""));
        const replacement = resolveVariable(variableNames, funnelVariables, claimData);
        templatedText = templatedText.replace(match, replacement);
      }
    }
  }

  return templatedText;
}
