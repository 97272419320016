
import Vue from "vue";
import { getProps } from "./props";

export default Vue.extend({
  name: "BlockManualVehicle",
  props: {
    ...getProps(),
  },
  data() {
    return {
      view: "regInput",
    };
  },
});
