
// eslint-disable-next-line no-unused-vars
import { Props } from "@/types";
import BlockInputWrapper from "@/components/shared/blocks/inputwrapper.vue";
import Vue, { PropType } from "vue";
import { themesComputed } from "@/state/helpers";
import { inputProps } from "@/helpers/ClaimsGate/blocks/inputProps";
const inputPropsValue: Props.BlockInput = inputProps({
  extraProps: ["elementId", "placeholder", "inputType", "maxlength", "autocomplete", "name"],
  placeholder: "Type here",
});
export default Vue.extend({
  name: "BlockInput",
  components: { BlockInputWrapper },
  props: {
    ...inputPropsValue,
    answer: {
      type: [String, Number] as PropType<string | number>,
      required: false,
    },
    inputMode: {
      type: String as PropType<string>,
      default: "text",
    },
  },
  data() {
    return {
      selected: false,
      newAnswer: "" as number | string,
      hover: false,
    };
  },
  watch: {
    /**
     * Allow for the parent to communicate prop changes to value
     * @param  {string} answer
     * @return {void}
     */
    answer: {
      handler: function (val) {
        if (val) {
          this.newAnswer = val;
        }
      },
    },
  },
  methods: {
    handleInput(input: string): void {
      this.newAnswer = input;
      this.$emit("update:answer", this.newAnswer);
      this.$emit("update:state", null);
    },
  },
  mounted() {
    this.newAnswer = this.answer ?? "";
  },
  computed: {
    ...themesComputed,

    /** Calculates the primary styles to be applied to the input */
    primaryStyles(): Record<string, string | number> {
      return this.theme?.primaryStyles ?? {};
    },
    /**
     * If theme from vuex has not been intitalized, default to large
     */
    size(): string {
      if (this.theme?.size?.length > 0) {
        return this.theme.size;
      } else {
        return "lg";
      }
    },
    /**
     * 'phoneNumber' is not a supported input type for b-form-input, but is useful for browser autocompletes
     *  Convert it to number for b-form-input
     */
    type(): string {
      return this.inputType === "phoneNumber" ? "text" : this.inputType;
    },
  },
});
