var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BlockInputWrapper',{attrs:{"info":_vm.info,"padding":_vm.padding,"label":_vm.label,"invalidFeedback":_vm.invalidFeedback,"state":_vm.state}},[_c('b-form-radio-group',{ref:'single-select-group',scopedSlots:_vm._u([{key:"default",fn:function({ ariaDescribedby }){return [_c('b-row',{staticClass:"justify-content-center no-gutters"},[_vm._l((_vm.newOptions),function(option,index){return [_c('b-col',{key:option.id,staticClass:"mb-0",attrs:{"cols":"12"}},[_c('b-card',{key:index,ref:'q' + index,refInFor:true,staticClass:"border mb-0 p-1",class:{
              selected: _vm.isSelected(index),
              deselected: !_vm.isSelected(index),
              isValid: _vm.state === true && _vm.isSelected(index),
              isInvalid: _vm.state === false,
            },attrs:{"no-body":"","state":_vm.state},on:{"click":function($event){_vm.select(index);
              _vm.makeRadioSelection('q' + index);},"change":function($event){return _vm.$emit('update:state', null)}}},[_c('div',{class:`custom-radio b-custom-control-${_vm.theme.size} ${
                _vm.theme.size === 'lg' ? 'p-3 pl-4' : _vm.theme.size === 'md' ? 'p-2 pl-3' : 'px-1'
              }`},[_c('b-form-radio',{class:!_vm.isRTL ? 'mr-2' : '',staticStyle:{"display":"inline-block"},attrs:{"aria-describedby":ariaDescribedby}}),_c('span',{staticClass:"font-weight-normal m-0",staticStyle:{"display":"inline-block"},style:(_vm.theme.textColourStyles)},[_vm._v(" "+_vm._s(_vm.$te("blocks.singleSelect." + _vm.formatOption(option).replace(/\.$/, "")) ? _vm.$t("blocks.singleSelect." + _vm.formatOption(option).replace(/\.$/, "")) : _vm.formatOption(option))+" ")])],1)])],1)]})],2),(_vm.other && _vm.newAnswer === 'Other')?_c('b-input-group',{ref:'other',staticClass:"pt-1"},[_c('b-form-input',{class:_vm.disabled ? 'disabled' : '',attrs:{"id":"input","type":"text","placeholder":"Your answer","size":_vm.theme.size,"required":_vm.required,"state":_vm.state,"disabled":_vm.disabled,"aria-describedby":"input-live-feedback input-live-help","trim":""},on:{"update":_vm.emitOtherAnswer,"change":function($event){return _vm.$emit('update:state', null)}},model:{value:(_vm.otherAnswer),callback:function ($$v) {_vm.otherAnswer=$$v},expression:"otherAnswer"}})],1):_vm._e()]}}]),model:{value:(_vm.newAnswer),callback:function ($$v) {_vm.newAnswer=$$v},expression:"newAnswer"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }