import { render, staticRenderFns } from "./motorSpecs.vue?vue&type=template&id=61bd8ec1&scoped=true&"
import script from "./motorSpecs.vue?vue&type=script&lang=ts&"
export * from "./motorSpecs.vue?vue&type=script&lang=ts&"
import style0 from "./motorSpecs.vue?vue&type=style&index=0&id=61bd8ec1&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61bd8ec1",
  null
  
)

export default component.exports